import { useEffect, useState } from 'react';
import { Image, Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { Home, LogoWhite, ChangePassword, Promotion, Organization, Logout, Notification } from '../../assets';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { MANAGE_COMMON } from '../../redux/actions/actionTypes';
import { LOGIN_PATH } from '../../constant';
const listPathSideBar = [
    '/admin',
    '/admin/create-account',
    '/admin/manage-promotion',
    '/admin/manage-organization',
    '/admin/notification',
]
const SideBar = () => {
    const [activeKey, setActiveKey] = useState<string>();
    const pathname = window.location.pathname //returns the current url minus the domain name    
    const history = useHistory();
    const dispatch = useDispatch();
    const { notificationsCount } = useSelector((app: AppState) => app.common);

    useEffect(() => {
        setActiveKey((listPathSideBar.indexOf(pathname)).toString())

        dispatch(MANAGE_COMMON.COUNT_NOTIFICATION());
    }, [history, pathname]);

    return (
        <div className='background'>
            <Menu
                mode='inline'
                onClick={(e) => setActiveKey(e.key.toString())}
                theme="dark"
                activeKey={activeKey}
                className="menu"
                defaultOpenKeys={['0']}>
                <Image
                    preview={false} src={LogoWhite}
                    className='logo_image'
                    style={{ width: '100%', }}
                />
                <Menu.Item key="0" className="menu_item" icon={
                    <img style={{ width: 24, height: 24 }}
                        src={Home}></img>
                } >
                    <Link to={'/admin'}>Quản lý đơn</Link>
                </Menu.Item>
                <Menu.Item key="1" className="menu_item" icon={
                    <img style={{ width: 24, height: 24 }}
                        src={ChangePassword}></img>
                } >
                    <Link to={'/admin/create-account'}>Tạo tài khoản</Link>
                </Menu.Item>
                <Menu.Item key="2" className="menu_item" icon={
                    <img style={{ width: 24, height: 24 }}
                        src={Promotion}></img>
                } >
                    <Link to={'/admin/manage-promotion'}>Khuyến mại</Link>
                </Menu.Item>
                <Menu.Item key="3" className="menu_item" icon={
                    <img style={{ width: 24, height: 24 }}
                        src={Organization}></img>
                } >
                    <Link to={'/admin/manage-organization'}>Tổ chức</Link>
                </Menu.Item>
                <Menu.Item key="4" className="menu_item"
                    icon={
                        <img style={{ width: 24, height: 24 }}
                            src={Notification}></img>
                    }
                >
                    <Link to={'/admin/notification'}>
                        {notificationsCount > 0 ? <div>Thông báo ({notificationsCount})</div> : <div>Thông báo</div>}
                    </Link>
                </Menu.Item>
                <Menu.Item style={{ position: "absolute", bottom: 0, }} className="menu_item" icon={
                    <img style={{ width: 24, height: 24 }}
                        src={Logout}></img>
                }
                    onClick={() => {
                        localStorage.removeItem('auth');
                        localStorage.removeItem('refreshToken');
                        history.push(LOGIN_PATH);
                    }}
                >
                    Đăng xuất
                </Menu.Item>
            </Menu>
        </div>
    );
};

export default SideBar;
