import { applyMiddleware, createStore } from 'redux';
import createRootReducer from '../reducers';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const enhancers = [
  applyMiddleware( ...middlewares)
];
export const store = createStore(
  createRootReducer(),
{},
  composeWithDevTools(...enhancers),
);
sagaMiddleware.run(rootSaga)



