import { useEffect } from 'react';
import './App.css';
import { Switch, Redirect, Route, useLocation, useHistory } from "react-router-dom";
import Login from './pages/Login';
import SideBar from './component/Sidebar';
import { Layout } from 'antd';
import CreateAccount from './pages/CreateAccount';
import ListRequest from './pages/ListRequest';
import ManagePromotion from './pages/ManagePromotion';
import Organization from './pages/Organization';
import Notification from './pages/Notification';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { LOGIN_PATH } from './constant';

const { Content } = Layout;

function App() {
  const location = useLocation();
  const history = useHistory();
  const checkIsLogin = () => {
    const token = localStorage.getItem('auth')
    return token !== null && token !== undefined && token !== ''
  };

  useEffect(() => {
    const isLogin = checkIsLogin();
    if (location.pathname === '/') {
      if (!isLogin) {
        history.push(LOGIN_PATH);
      }
    } else if (location.pathname === LOGIN_PATH) {

      if (isLogin) {
        history.push('/admin');
      }
    }
  }, [history, location.pathname]);
  return (
    <Provider store={store}>
      <Switch>
        <Route exact path={LOGIN_PATH} component={Login} />
        {checkIsLogin() &&
          <>
            <div className='page_container'>
              <SideBar />
              <Layout>
                <Content>
                  <Switch>
                    <Route exact path="/admin/create-account">
                      <CreateAccount />
                    </Route>
                    <Route exact path="/admin">
                      <ListRequest />
                    </Route>
                    <Route exact path="/admin/manage-promotion">
                      <ManagePromotion />
                    </Route>
                    <Route exact path="/admin/manage-organization">
                      <Organization />
                    </Route>
                    <Route exact path="/admin/notification">
                      <Notification />
                    </Route>
                    <Route
                      render={props =>
                        <Redirect
                          to={{
                            pathname: checkIsLogin() ? '/admin' : LOGIN_PATH,
                            state: { from: location }
                          }}
                        />}
                    />
                  </Switch>
                </Content>
              </Layout>
            </div>
          </>
        }
        <Route
          render={props =>
            <Redirect
              to={{
                pathname: checkIsLogin() ? '/admin' : LOGIN_PATH,
                state: { from: location }
              }}
            />}
        />
      </Switch>
    </Provider>
  );
}

export default App;
