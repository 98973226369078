import React, { useEffect, useState } from 'react';
import './index.css';
import { reqConfirmPayment ,reqAcceptPurchaseRequest, reqGetListPurchaseRequest, reqListOrganization, reqRejectPurchaseRequest } from '../../services';
import { OrganizationInfor, PurchaseRequest, PurchaseRequestDetail, ResListPurchaseRequestData } from '../../constant/types';
import { Button, DatePicker, Input, Select, Spin, Table, message } from 'antd';
import { parseToVND } from '../../helper/index';
import { dateFormatList } from '../../constant';
import dayjs from 'dayjs';
import moment from 'moment';
const statusFilter = [
    {
        text: 'Chưa phân phối',
        value: 1,
    },
    {
        text: 'Đã phân phối',
        value: 2,
    },
    {
        text: 'Đã từ chối',
        value: 3,
    }
]
const ListRequest: React.FC = () => {
    const [listRequest, setListRequest] = useState<ResListPurchaseRequestData>();
    const [listOrganization, setListOrganization] = useState<OrganizationInfor[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [startDateUnix, setStartDateUnix] = useState<number | undefined>();
    const [endDateUnix, setEndDateUnix] = useState<number | undefined>();
    const [statusKey, setStatusKey] = useState<number>();
    const [batchIdKey, setBatchIdKey] = useState<string>('');
    const [pageSize, setPageSize] = useState<number>(20);
    const [purchasedByOrganization, setPurchasedByOrganization] = useState<string>();

    const getListRequest = async (page?: number, startDate?: number, endDate?: number, status?: number, pageSizeK?: number, batch_id?: string, organization_id?: string) => {
        const statusK = (status === 5) ? undefined : status || statusKey;
        const purchased_by_organizationK = organization_id === 'all' ? undefined : organization_id || purchasedByOrganization;
        setStartDateUnix(startDate);
        setEndDateUnix(endDate);
        setStatusKey(statusK);
        setPurchasedByOrganization(purchased_by_organizationK);

        const finalData: {
            start_date?: number,
            end_date?: number,
            size: number,
            page: number,
            status?: number,
            page_size?: number,
            batch_id?: string,
            organization_id?: string
        } = {
            start_date: startDate == 1 ? undefined : (startDate && !isNaN(startDate)) ? startDate : startDateUnix,
            end_date: endDate == 1 ? undefined : (endDate && !isNaN(endDate)) ? endDate : endDateUnix,
            size: pageSizeK || pageSize,
            page: page || pageNumber,
            status: statusK,
            batch_id: batch_id === '' ? undefined : batch_id || batchIdKey,
            organization_id: purchased_by_organizationK
        };

        for (const key in finalData) {
            const value = finalData[key as keyof typeof finalData];
            if (value == undefined || value == null || value == '' || value == 0) {
                delete finalData[key as keyof typeof finalData];
            }
        }

        const response = await reqGetListPurchaseRequest(finalData);
        setListRequest(response);
    };

    const getListOrganization = async () => {
        const data = await reqListOrganization()
        setListOrganization(data)
    }

    useEffect(() => {
        getListRequest(
            1,
            moment().startOf('month').startOf('day').unix(),
            moment().endOf('day').unix(),
            undefined,
            20
        )
        getListOrganization()
    }, []);

    const confirmPayment = async (id: string) => {
        setLoading(true)
        const response = await reqConfirmPayment({ batch_id: id })
        if (response) {
            setLoading(false)
            getListRequest()
            message.success('Xác nhận thanh toán thành công')
        }
        else {
            setLoading(false)
            message.error('Có lỗi xảy ra. Vui lòng thử lại.')
        }
    }

    const acceptPurchase = async (id: string) => {
        setLoading(true)
        const response = await reqAcceptPurchaseRequest({ batch_id: id })
        if (response) {
            setLoading(false)
            getListRequest()
            message.success('Xác nhận đơn hàng thành công')
        }
        else {
            setLoading(false)
            message.error('Có lỗi xảy ra. Vui lòng thử lại.')
        }
    }

    const rejectPurchase = async (id: string) => {
        setLoading(true)
        const response = await reqRejectPurchaseRequest({ batch_id: id })
        if (response) {
            setLoading(false)
            getListRequest()
            message.success('Từ chối đơn hàng thành công')
        }
        else {
            setLoading(false)
            message.error('Có lỗi xảy ra. Vui lòng thử lại.')
        }
    }

    const columns = [
        {
            title: 'ID Lô hàng',
            dataIndex: 'batch_id',
            key: 'batch_id',
        },
        {
            title: "Trạng thái",
            render: (text: any, record: PurchaseRequest) =>
                <div className={record.status == 1 ? "key inactive_key" : record.status == 2 ? "key active_key" : "key revoke_key"}>
                    <h4 style={{ fontSize: 14 }}>{record.status == 0 ? "Chưa xác minh" : record.status == 1 ? "Chưa phân phối" : record.status == 2 ? "Đã phân phối" : "Đã từ chối"}</h4>
                </div>,
            key: 'status',
            filters: statusFilter,
            onFilter: (value: any, record: PurchaseRequest) => record.status == value ? true : false,
        },
        {
            title: "Ngày tạo",
            render: (text: any, record: PurchaseRequest) => <p className='createdTime'>{dayjs.unix(record.created_at).format('hh:mm DD/MM/YYYY')}</p>,
            key: 'created_at',
        },
        {
            title: 'Tổ chức',
            dataIndex: 'organization_id',
            key: 'requested_by_orgaorganization_idnization',
            render: (text: any, record: PurchaseRequest) => {
                const orgName = listOrganization?.find((item) => item.id == record.organization_id)?.name;
                return <p>{orgName}</p>;
            }
        },
        {
            title: 'Chi tiết',
            key: 'detail',
            render: (text: any, record: PurchaseRequest) => {
                return record.detail.map((item: any) => {
                    return <p className='detail'>Gói {item.license_pack} Số lượng {item.quantity} </p>;
                })
            }
        },
        {
            title: 'Tổng tiền ',
            dataIndex: 'total_price',
            key: 'total_price',
            render: (text: any, record: PurchaseRequest) => {
                return <p className='totalPrice' >{parseToVND(Number(record.total_price))} VND</p>;
            }
        },
        {
            title: "Trạng thái thanh toán",
            render: (text: any, record: PurchaseRequest) =>
                <div className={record.payment_status == 0 ? "key inactive_key" : record.payment_status == 1 ? "key active_key" : "key revoke_key"}>
                    <h4 style={{ fontSize: 14 }}>{record.payment_status == 0 ? "Chưa thanh toán" : record.payment_status == 1 ? "Đã thanh toán" : "null"}</h4>
                </div>,
            key: 'status',
            filters: statusFilter,
            onFilter: (value: any, record: PurchaseRequest) => record.status == value ? true : false,
        },
        {
            title: 'Hành động',
            key: 'detail',
            render: (text: any, record: PurchaseRequest) => {
                return record.status == 1 ? <div className='action' >
                    <Button onClick={() => acceptPurchase(record.batch_id)} type='primary' style={{ marginRight: 2 }}>Phân phối</Button>
                    <Button onClick={() => rejectPurchase(record.batch_id)}>Từ chối</Button>
                </div> : <></>
            }
        },
        {
            title: 'Xác nhận',
            key: 'detail',
            render: (text: any, record: PurchaseRequest) => {
                return record.status == 2 && record.payment_status == 0 ? <div>
                <Button onClick={() => confirmPayment(record.batch_id)} type='primary' style={{ marginRight: 2 }}>Xác nhận thanh toán</Button>
            </div> : <></>
            }
        }
    ]
    const { RangePicker } = DatePicker;

    return (
        <div className=" h-screen flex flex-col items-center p-8">
            <Spin fullscreen spinning={loading}  ></Spin>
            <div className='container_sub_content'>
                <p className="text-4xl font-bold">Quản lý đơn hàng</p>

                <div style={{ marginTop: 12 }} className='flex flex-row items-center gap-2'>
                    <Input placeholder='Tìm tiếm theo ID' className='w-[20%]' 
                    
                    onChange={(value) => {
                        setBatchIdKey(value.target.value.toString())
                        setPageNumber(1);
                        getListRequest(1, startDateUnix, endDateUnix, statusKey, pageSize, value.target.value.toString(), purchasedByOrganization)
                    }
                    }></Input>
                    <Select onChange={(value) => {
                        setPurchasedByOrganization(value.toString());
                        setPageNumber(1);
                        getListRequest(1, startDateUnix, endDateUnix, statusKey, pageSize, undefined, value.toString());
                    }}
                        placeholder='Lọc theo tổ chức'
                        value={purchasedByOrganization}
                        style={{ width: 200 }}
                        options={
                            listOrganization?.map((item) => { return { label: item.name, value: item.id } }).concat([{ label: 'Tất cả', value: 'all' }])
                        }
                    ></Select>
                    <span style={{ fontWeight: 'bold' }}>Lọc theo ngày: </span>
                    <RangePicker
                        className="mr-3"
                        defaultValue={[
                            dayjs().startOf('month'),
                            dayjs()
                        ]}
                        placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                        format={dateFormatList}
                        onChange={(value, dateString) => {
                            if (value === null) {
                                setEndDateUnix(undefined);
                                setStartDateUnix(undefined);
                                getListRequest(1, 1, 1);
                                setPageNumber(1)
                                return;

                            } else {
                                var formattedStartDate = moment(dateString[0], "DD/MM/YYYY").format("YYYY-MM-DD");
                                var formattedEndDate = moment(dateString[1], "DD/MM/YYYY").format("YYYY-MM-DD");
                                var unixTimestampStart = moment(formattedStartDate).unix();
                                var unixTimestampEnd = moment(formattedEndDate).add(1, 'days').unix();
                                if (unixTimestampStart > unixTimestampEnd) {
                                    message.error('Ngày bắt đầu không thể lớn hơn ngày kết thúc');
                                    return;
                                }

                                setStartDateUnix(unixTimestampStart);
                                setEndDateUnix(unixTimestampEnd);
                                setPageNumber(1);
                                getListRequest(1, unixTimestampStart, unixTimestampEnd);
                            }
                        }}
                    />
                    <Select
                        onChange={(value) => {
                            setStatusKey(Number(value));
                            setPageNumber(1);
                            getListRequest(1, startDateUnix, endDateUnix, Number(value));
                        }}
                        placeholder='Lọc theo trạng thái'

                        value={statusKey}
                        style={{ width: 200 }}
                        options={
                            statusFilter.map((item) => {
                                return { label: item.text, value: item.value }
                            }).concat([{ label: 'Tất cả', value: 5 }])
                        } ></Select>
                </div>
                <Table
                    className="overflow-x-hidden overflow-y-auto text-justify h-[100%] pb-24"
                    dataSource={listRequest?.page}
                    columns={columns}
                    pagination={
                        {
                            total: listRequest?.totalResult,
                            current: pageNumber,
                            onChange: (pageNumberK, pageSizeK) => {
                                if (pageSizeK !== pageSize) {
                                    setPageSize(pageSizeK);
                                    setPageNumber(1);
                                    getListRequest(1, startDateUnix, endDateUnix, statusKey, pageSizeK)
                                } else {
                                    setPageNumber(pageNumberK);
                                    getListRequest(pageNumberK, startDateUnix, endDateUnix, statusKey, pageSizeK);
                                }
                            },
                            pageSize: pageSize,
                        }
                    }
                />
            </div>
        </div>
    );
};

export default ListRequest;