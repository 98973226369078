import { all, call, put, takeLatest } from 'redux-saga/effects';
import { MANAGE_COMMON } from '../../actions/actionTypes';
import { GET_NOTIFICATION_COUNT } from '../../actions/types';
import { reqGetNotification } from '../../../services';
import { TNotification } from '../../../constant/types';

function* getNotification() {
    // yield put(MANAGE_COMMON.COUNT_NOTIFICATION(payload));
    try {
      const response: TNotification[] = yield call(reqGetNotification);
      const count = response.filter((item) => item.status === 0).length

      yield put(MANAGE_COMMON.COUNT_NOTIFICATION_SUCCESS({
        notificationsCount: count,
        listNotification: response
      }));
    } catch (e) {
      // message.error(e.message);
    } finally {
      // yield put(COMMON.LOADING(false));
    }
}



export default function* notificationSagas() {
  yield all([
    takeLatest(GET_NOTIFICATION_COUNT, getNotification),
  ]);
}
