import { combineReducers } from 'redux';
import { RootStateOrAny } from 'react-redux';
import rootCommon, { IManageCommon } from './Common';


export interface IAppState {
  common: IManageCommon;
}
export const rootReducer = () => combineReducers<IAppState>({
  common: rootCommon
});

export type AppState = RootStateOrAny;
export default rootReducer;
