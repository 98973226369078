import React, { useEffect, useState } from 'react';
import { reqListOrganization, reqTotalKeyOfOrganization } from '../../services';
import { OrganizationInfor, totalKeyStatusOfOrganization, ResTotalKeyOfOrganization } from '../../constant/types';
import { Table } from 'antd';

const Organization: React.FC = () => {
    const [listOrganization, setListOrganization] = useState<(OrganizationInfor & { totalKeyData?: totalKeyStatusOfOrganization[] })[]>([]);
    const getListOrganization = async () => {
        const data = await reqListOrganization();

        // Fetch total key data for each organization
        const updatedData = await Promise.all(
            data.map(async (org) => {
                const response = await reqTotalKeyOfOrganization(org.id.toString());
                const totalKeysPurchased = response.reduce((acc, keyData) => acc + parseInt(keyData.count), 0);
                return { ...org, totalKeyData: response, totalKeysPurchased };
            
            }
        ));

        setListOrganization(updatedData);
    };

    useEffect(() => {
        getListOrganization();
    }, []);

    const columns = [
        {
            title: 'ID tổ chức',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Tên tổ chức',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Tổng quan',
            key: 'total_key',
            render: (value: any, record: OrganizationInfor & { totalKeyData?: totalKeyStatusOfOrganization[] }) => {
                return (
                    <><div>
                        <p> <strong>Số key đã mua: </strong> {record.totalKeysPurchased}</p>
                    </div><div>
                            {record.totalKeyData ? (
                                record.totalKeyData.map((keyData, index) => {
                                    if (keyData.status == '1') {
                                        return (
                                            <div key={index}>
                                                <p>Số key chưa kích hoạt: {keyData.count}</p>
                                            </div>
                                        );
                                    } else if (keyData.status == '3') {
                                        return (
                                            <div key={index}>
                                                <p>Số key đã kích hoạt: {keyData.count}</p>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div key={index}>
                                                <p></p>
                                            </div>
                                        );
                                    }
                                })
                            ) : (
                                <p>N/A</p>
                            )}
                        </div></>
                );
            }
        },
    ];

    return (
        <div className="h-screen flex flex-col items-center p-8">
            <div className='container_sub_content'>
                <p className="text-4xl font-bold">Quản lý tổ chức</p>
                <Table
                    className="overflow-x-hidden overflow-y-auto text-justify h-[100%] pb-24"
                    columns={columns}
                    dataSource={listOrganization}
                />
            </div>
        </div>
    );
};

export default Organization;
