import { COMMON_STATE, GET_NOTIFICATION_COUNT } from "./types";

export interface ActionType {
  type: string,
  payload: any
}

export const MANAGE_COMMON = {
  COUNT_NOTIFICATION: () => {
    return {
      type: GET_NOTIFICATION_COUNT,
      
    };
  },
  COUNT_NOTIFICATION_SUCCESS: (payload= {}) => {
    return {
      type: COMMON_STATE,
      payload
    };
  }
}