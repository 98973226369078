import React, { useEffect, useState } from 'react';
import { reqAcceptPurchaseRequest, reqCreatePromotion, reqDeletePromotion, reqGetListPromotion, reqLicensePack, reqListOrganization, reqUpdatePromotion } from '../../services';
import { LicensePack, OrganizationInfor, Promotion } from '../../constant/types';
import { Button, DatePicker, Form, InputNumber, Modal, Select, Spin, Table, message } from 'antd';
import moment from 'moment';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';

const ManagePromotion: React.FC = () => {
    const [listRequest, setListRequest] = useState<Promotion[]>();
    const [listOrganization, setListOrganization] = useState<OrganizationInfor[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const [listLicensePack, setListLicensePack] = useState<LicensePack[]>()
    const getListRequest = async () => {
        const response = await reqGetListPromotion();
        setListRequest(response);
    };
    const getListOrganization = async () => {
        const data = await reqListOrganization()
        setListOrganization(data)
    }
    const getListLicensePack = async () => {
        const data = await reqLicensePack()
        setListLicensePack(data)

    }
    useEffect(() => {
        getListLicensePack()
        getListRequest()
        getListOrganization()
    }, []);

    const rejectPromotion = async (id: string) => {
        const response = await reqDeletePromotion({ promotion_id: id })
        if (response) {
            getListRequest()
            message.success('Xoá promotion thành công')
        }
        else {
            message.error('Có lỗi xảy ra. Vui lòng thử lại.')
        }
    }
    const [type, setType] = useState<string>('add')
    function isNumber(value: any) {
        return typeof value === 'number' && !isNaN(value);
    }
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [form] = useForm()
    const ManagePromotion = async (data: any) => {
        const response =
            type == "add" ? await reqCreatePromotion({ ...data, expired_time: dayjs(data.expired_time).unix(), amount: Number(data.amount) / 100 }) :
                await reqUpdatePromotion({ ...data, expired_time: isNumber(data.expired_time) ? data.expired_time : dayjs(data.expired_time).unix(), amount: Number(data.amount) / 100 })
        if (response) {
            setOpenModal(false)
            type == "add" ? message.success('Thêm khuyến mại thành công') : message.success('Sửa khuyến mại thành công')
            form.resetFields()
            getListRequest()
        } else {
            message.error('Có lỗi xảy ra. Vui lòng thử lại.')
        }
    }
    const columns = [
        {
            title: 'ID ',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Gói khả dụng',
            dataIndex: 'license_pack_id',
            render: (text: any, record: Promotion) => {
                const licensePackName = listLicensePack && listLicensePack
                    .filter((org) => record.license_pack_id == org.id)
                    .map((org) => org.name);
                return <div>
                    {licensePackName && licensePackName.length > 0 && licensePackName.map((item) => <p>{item}</p>)}
                </div>
            }
        },
        {
            title: 'Tổ chức được áp dụng',
            dataIndex: 'organization_id',
            render: (text: any, record: Promotion) => {
                const orgName = listOrganization && listOrganization!
                    .filter((org) => record.organization_id == (org.id))
                    .map((org) => org.name);
                return <div>
                    {orgName && orgName.map((item) => <p>{item}</p>)}
                </div>
            }
        },

        {
            title: "Chiết khấu",
            dataIndex: 'amount',
            key: 'amount',
            render: (text: any, record: Promotion) => {
                return <p>{Number(text) * 100}%</p>;

            }
        },
        {
            title: 'Ngày hết hạn',
            dataIndex: 'expired_time',
            key: 'expired_time',
            render: (text: any, record: Promotion) => {
                return <p>{moment.unix(text).format("DD/MM/YYYY")}</p>;

            }
        },
        {
            title: 'Action',
            key: 'detail',
            render: (text: any, record: Promotion) => {
                return <div>
                    <Button onClick={() => {
                        setType("update")
                        setOpenModal(true);
                        form.setFieldsValue({
                            license_pack_id: record.license_pack_id,
                            // organization_id: record.organization_id,
                            amount: Number(record.amount) * 100,
                            expired_time: dayjs.unix(record.expired_time),
                            id: record.id
                        })
                    }} type='primary' style={{ marginRight: 2 }}>Sửa</Button>
                    <Button danger onClick={() => rejectPromotion(record.id)}>Xoá</Button>
                </div>
            }
        }
    ]

    return (
        <div className=" h-screen flex flex-col items-center p-8">
            <Spin fullscreen spinning={loading}  ></Spin>
            <Modal open={openModal} onCancel={() => setOpenModal(false)} onOk={() => form.submit()}>
                <Form form={form}
                    onFinish={ManagePromotion}
                    style={{ width: "80%" }}
                    labelCol={{
                        span: 8,
                    }}

                    labelAlign='left'>
                    <Form.Item name="license_pack_id"
                        label={'Chọn gói'}>
                        <Select
                            placeholder="Chọn gói"
                            options={listLicensePack?.map((item) => {
                                return {
                                    label: item.name,
                                    value: item.id
                                }
                            })}>
                        </Select>
                    </Form.Item>
                    {type === "update" && <Form.Item name="id" hidden>

                    </Form.Item>}
                    {type !== "update" && <Form.Item name="organization_id">
                        <Select
                            placeholder="Chọn tổ chức"
                            options={listOrganization?.map((item) => {
                                return {
                                    label: item.name,
                                    value: item.id
                                }
                            })}>

                        </Select>
                    </Form.Item>}
                    <Form.Item
                        label='Chiết khấu (%)'
                        initialValue='0'
                        name="amount">
                        <InputNumber
                            style={{
                                width: 200,
                            }}
                            min={0}
                            max={100}
                            step={1}
                        />
                    </Form.Item>
                    <Form.Item
                        name="expired_time"
                        label={'Ngày hết hạn'}
                        rules={[{ type: 'object', required: true, message: 'Vui lòng chọn ngày hết hạn!' }]}

                    >
                        <DatePicker
                            format='DD/MM/YYYY'
                            placeholder='DD/MM/YYYY'
                        />
                    </Form.Item>
                </Form>

            </Modal >
            <div className='container_sub_content'>
                <div>
                    <p className="text-4xl font-bold">Quản lý Khuyến Mại</p>
                    <Button   
                    style={{
                        backgroundColor: '#008B52', // Custom background color
                        color: 'white',              // Text color
                        fontSize: 16,                // Font size
                        cursor: 'pointer',           // Pointer cursor on hover
                    }} onClick={() => {
                        setOpenModal(true);
                        setType("add")
                    }}>Thêm khuyến mại</Button>
                </div>
                <Table
                    dataSource={listRequest}
                    columns={columns}
                    className="overflow-x-hidden overflow-y-auto text-justify h-[100%] pb-24"

                />
            </div>
        </div>
    );
};

export default ManagePromotion;