import { message } from "antd";
import { AuthLoginResponse, CalculatePriceData, LoginData, PayLoadGetListKey, PayloadChangePassword, PayloadCreateAccount, PayloadListPurchaseRequest, PayloadVerifyPurchase, PayloadPromotionCreate, PayloadPromotionUpdate, RenewTokenData, ResCalculatePrice, ResGetNotification, ResLicensePack, ResListKey, ResListOrganization, ResListPurchaseRequest, ResListPromotion, ResPurchaseId, ResTotalKeyOfOrganization } from "../constant/types";
import { ApiResponse, axiosAuth, axiosCatch } from "./AxiosHelper";
import { AxiosResponse } from "axios";
import { LOGIN_PATH } from "../constant";

export const reqAuthLogin = async (loginData: LoginData,) => {
  const response: ApiResponse<AuthLoginResponse> = await axiosAuth().post('/auth/login', loginData).catch(axiosCatch);
  const { accessToken, refreshToken } = response.data.data;
  if (accessToken) {
    message.success('Đăng nhập thành công');
    localStorage.setItem('auth', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    window.location.replace(LOGIN_PATH);
  }
  else {
    message.error('Đăng nhập thất bại');
  }
};

export const reqAuthRenewToken = async (renewTokenData: RenewTokenData) => {
  return await axiosAuth().post('/auth/refresh-tokens', renewTokenData).catch(axiosCatch);
};


export const reqGetListKey = async (params: PayLoadGetListKey) => {
  const response: ApiResponse<ResListKey> = await axiosAuth().get('/active-key/get-list-key', { params }).catch(axiosCatch);
  return response.data.data;
};


export const reqLicensePack = async () => {
  const response: ApiResponse<ResLicensePack> = await axiosAuth().get('/license-pack/list-license-pack').catch(axiosCatch);
  return response.data.data;
}

export const reqCalculatePrice = async (data: CalculatePriceData) => {
  const calculate_price: AxiosResponse<ResCalculatePrice> = await axiosAuth().post('/purchase/calculate-price', data).catch(axiosCatch);
  return calculate_price.data.data;
};

export const reqPurchaseRequest = async (data: CalculatePriceData) => {
  const calculate_price: AxiosResponse<ResPurchaseId> = await axiosAuth().post('/purchase/purchase-request', data).catch(axiosCatch);
  return calculate_price.data.data;
};

export const reqChangePassword = async (data: PayloadChangePassword) => {
  const passwordChange = await axiosAuth().post('/auth/change-password', data).catch(axiosCatch);
  if (passwordChange.data) {
    message.success('Đổi mật khẩu thành công');
  }
};

export const reqVerifyPurchase = async (data: PayloadVerifyPurchase) => {
  const purchaseVerified = await axiosAuth().post('/purchase//verify-purchase-request', data).catch(axiosCatch);
  if (purchaseVerified.data && purchaseVerified.data.code == 200) {
    return true
  }
  else {
    return false
  }
};

export const reqListOrganization = async () => {
  const response: ApiResponse<ResListOrganization> = await axiosAuth().get('/organization/admin/list-organization').catch(axiosCatch);
  return response.data.data;
}

export const reqTotalKeyOfOrganization = async (organization_id: string) => {
  const response: ApiResponse<ResTotalKeyOfOrganization> = await axiosAuth().get(`/license-key/admin/total-key-of-organization?organization_id=${organization_id}`).catch(axiosCatch);
  return response.data.data;
}

export const reqCreateAccount = async (data: PayloadCreateAccount) => {
  const response = await axiosAuth().post('/admin/account/create', data).catch(axiosCatch);
  if (response.data.code && response.data.code == 201) {
    return true
  }
  else {
    return false
  }
}

export const reqGetListPurchaseRequest = async (params : PayloadListPurchaseRequest) => {
  const response: ApiResponse<ResListPurchaseRequest> = await axiosAuth().get('/purchase/admin/get-list-purchase-request', {params}).catch(axiosCatch);
  return response.data.data;
}

export const reqAcceptPurchaseRequest = async (data: { batch_id: string }) => {
  const response = await axiosAuth().post('/purchase/admin/accept-purchase-request', data).catch(axiosCatch);
  if (response.data.code && response.data.code == 200) {
    return true
  }
  else {
    return false
  }
}

export const reqRejectPurchaseRequest = async (data: { batch_id: string }) => {
  const response = await axiosAuth().post('/purchase/admin/reject-purchase-request', data).catch(axiosCatch);
  if (response.data.code && response.data.code == 200) {
    return true
  }
  else {
    return false
  }
}

export const reqConfirmPayment = async (data: { batch_id: string}) => {
  const response = await axiosAuth().post('/purchase/admin/payment-confirm', data).catch(axiosCatch);
  if (response.data.code && response.data.code == 200) {
    return true
  }
  else {
    return false
  }
}

export const reqGetListPromotion = async () => {
  const response: ApiResponse<ResListPromotion> = await axiosAuth().get('/promotion/admin/get-list-promotion').catch(axiosCatch);
  return response.data.data;
}

export const reqCreatePromotion= async (data: PayloadPromotionCreate) => {
  const response = await axiosAuth().post('/promotion/admin/create-promotion', data).catch(axiosCatch);
  if (response.data.code && response.data.code == 200) {
    return true
  }
  else {
    return false
  }
}

export const reqDeletePromotion = async (data: { promotion_id: string }) => {
  const response = await axiosAuth().post('/promotion/admin/delete-promotion', data).catch(axiosCatch);
  if (response.data.code && response.data.code == 200) {
    return true
  }
  else {
    return false
  }
}

export const reqUpdatePromotion= async (data: PayloadPromotionUpdate) => {
  const response = await axiosAuth().post('/promotion/admin/update-promotion', data).catch(axiosCatch);
  if (response.data.code && response.data.code == 200) {
    return true
  }
  else {
    return false
  }
}


export const reqGetNotification = async () => {
  const response: ApiResponse<ResGetNotification> = await axiosAuth().get('/notification/admin/get-notification').catch(axiosCatch);
  return response.data.data;
}

export const reqReadNotification = async (data: { notification_id: number }) => {
  const response = await axiosAuth().post('/notification/confirm-read-notification', data).catch(axiosCatch);

  if (response.data.code && response.data.code == 200) {
    return true
  }
  else {
    return false
  }
}

export function getTimeFromUnixTimestamp(unixTime: number): string {
  
  const currentTimestamp = Math.floor(Date.now() / 1000); // Current UNIX timestamp in seconds
  const diffInSeconds = currentTimestamp - unixTime;

  if (diffInSeconds < 60 * 60) {
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    return `${diffInMinutes} phút trước`;
  } else if (diffInSeconds < 24 * 60 * 60) {
    const diffInHours = Math.floor(diffInSeconds / (60 * 60));
    return `${diffInHours} giờ trước`;
  } else {
    const diffInDays = Math.floor(diffInSeconds / (24 * 60 * 60));
    return `${diffInDays} ngày trước`;
  }
}

export function unixToDateString(unixTime: number): string { const date = new Date(unixTime * 1000); const year = date.getFullYear(); const month = date.getMonth() + 1; const day = date.getDate(); const hours = date.getHours(); const minutes = date.getMinutes(); return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} - ${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`; }

export const reqCheckoutInvoice = async (data: { invoice_id: string }) => {
  const response = await axiosAuth().post('/invoice/admin/checkout-invoice', data).catch(axiosCatch);
  if (response.data.code && response.data.code == 200) {
    return true
  }
  else {
    return false
  }
}