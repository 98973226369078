import { message } from 'antd';
import axios from 'axios';
import { LOGIN_PATH } from '../constant';

export interface ApiResponse<T> {
    data: T,
    message?: string | number
    code?: string | number,
    status?: string | number
}

export interface RefreshTokenResponse {
    data: {
        accessToken: string,
        refreshToken: string
    };
}

const {
    REACT_APP_API_URL
} = process.env;



const instance = axios.create({
    baseURL: REACT_APP_API_URL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('auth');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export async function refreshTokenRequest(retries = 0): Promise<string> {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken || typeof refreshToken !== 'string' || refreshToken.trim() === '') {
        if (retries < 3) {
            // Refresh token not loaded yet, retry after a delay
            await new Promise((resolve) => setTimeout(resolve, 1000));
            return refreshTokenRequest(retries + 1);
        } else {
            if (!window.location.pathname.match(/login/)) {
                localStorage.removeItem('auth');
                localStorage.removeItem('refreshToken');
                window.location.replace(LOGIN_PATH);
            }
            throw new Error('Failed to obtain refresh token');
        }
    }

    return refreshToken;
}

const commonResponse = async (error: any) => {
    const originalRequest = error.config;
    const status = error?.response?.status;

    if (status === 403) {
        localStorage.removeItem('auth');
        localStorage.removeItem('refreshToken');
        window.location.replace(LOGIN_PATH);
        return axiosCatch(error);
    }

    if (!status || status === 503 || status === 500) {
        return axiosCatch(error);
    }
    if (status === 401) {
        const refreshToken = await refreshTokenRequest();
        try {
            const response = await axios.post(`${REACT_APP_API_URL}/auth/refresh-token`, { refreshToken });
            const accessTokenNew = response.data.data.accessToken;
            const refreshTokenNew = response.data?.data?.refreshToken;
            localStorage.setItem('auth', accessTokenNew);
            localStorage.setItem('refreshToken', refreshTokenNew);
            instance.defaults.headers.common.Authorization = `Bearer ${accessTokenNew}`;
            return instance(originalRequest);
        } catch (error) {
            localStorage.removeItem('auth');
            localStorage.removeItem('refreshToken');
            if (!window.location.pathname.includes('login')) {
                window.location.replace(LOGIN_PATH);
            }
        }
    }
    return Promise.reject(error);
};

instance.interceptors.response.use(
    (response) => {
        return response;
    }, commonResponse,);

export const axiosAuth = () => instance;

export const axiosCatch = (error: any) => {
    const defaultMessage = 'Lỗi khi kết nối đến server. Vui lòng thử lại.';
    if (error.response) {
        const errorMessage = error.response.data ? error.response.data.message ? error.response.data.message : JSON.stringify(error.response.data) : defaultMessage
        message.error(errorMessage);

        throw error.response.data;
    } else if (error.request) {
        message.error(error.message ? error.message.toString() : defaultMessage);

        throw {
            message: error.message
        };
    } else {
        message.error(defaultMessage);

        throw error;
    }
};
