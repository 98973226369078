import { Button, Form, Input, InputNumber, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { reqCreateAccount, reqListOrganization } from '../../services';
import { useForm } from 'antd/es/form/Form';
import './index.css';


const CreateAccount: React.FC = () => {
    const [listOrganization, setListOrganization] = useState<{
        label: string,
        value: string
    }[]>([])
    const [form] = useForm()
    const getListOrganization = async () => {
        const data = await reqListOrganization()
        setListOrganization(data.map((item) => {
            return {
                label: item.name,
                value: item.id
            }
        }))
    }
    useEffect(() => {
        getListOrganization()
    }, []);

    const regrexAtLeast = /^(?=.*[a-zA-Z])(?=.*\d).+$/

    const createAccount = async (data: any) => {
        const response = await reqCreateAccount(data)
        if (response) {
            message.success('Tạo tài khoản thành công')
            form.resetFields()
        }
    }
    return (
        <div className='page_container'>

            <div className='content_create_account'>
                <span className='login_heading'>Tạo tài khoản</span>
                <Form
                    labelCol={{
                        span: 6,
                    }}

                    labelAlign='left'
                    layout="horizontal"
                    form={form}
                    onFinish={createAccount}
                    className='form_login'>
                    <Form.Item
                        rules={[
                            { required: true, validateTrigger: 'onFinish', message: 'Vui lòng nhập email' },
                            {
                                type: 'email',
                                validateTrigger: 'onFinish',
                                message: 'Email không hợp lệ'
                            }
                        ]}
                        name='email'
                        label='Email'
                    >
                        <Input className='input_form' placeholder='Email' />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            { required: true, validateTrigger: 'onFinish', message: 'Vui lòng nhập email' },

                        ]}
                        name='name'
                        label='Tên đối tác'

                    >
                        <Input className='input_form' placeholder='Name' />
                    </Form.Item>
                    <Form.Item
                        rules={[{
                            required: true,
                            validateTrigger: 'onFinish',
                            message: 'Vui lòng nhập mật khẩu'
                        },
                        { min: 8, validateTrigger: 'onFinish', message: 'Mật khẩu phải có ít nhất 8 ký tự' },
                        {
                            validateTrigger: 'onFinish',
                            validator: (_, value) =>
                                regrexAtLeast.test(value) ? Promise.resolve() :
                                    Promise.reject('Mật khẩu có chứa ít nhất 1 kí tự số và 1 kí tự chữ cái'
                                    )
                        },
                        ]}
                        name='password'
                        label='Mật khẩu'

                    >
                        <Input.Password className='input_form' placeholder='Mật khẩu' />
                    </Form.Item>
                    <Form.Item
                        name="partner_level"
                        initialValue={1}

                        rules={[{
                            required: true,
                            validateTrigger: 'onFinish',
                            message: 'Vui lòng nhập partner level',
                            pattern: new RegExp(/^[0-9]+$/)
                        }
                        ]}
                        label='Partner Level'
                    >
                        <Input type='number' className='input_form' placeholder='Partner Level' />

                    </Form.Item>
                    <Form.Item
                        name="role"
                        hidden
                        initialValue={"partner"}
                    >
                    </Form.Item>
                    <Form.Item
                        name="organization_id"
                        label='Tổ chức'
                        rules={[{ required: true, message: 'Vui lòng chọn tổ chức' }]}
                    >
                        <Select placeholder={'Tổ chức'} className='input_form' options={listOrganization}></Select>
                    </Form.Item>
                    <Button className='form_button' type='primary' htmlType='submit'>Tạo tài khoản</Button>
                </Form>
            </div>

        </div>
    );
};

export default CreateAccount;